import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { faCaretDown, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const birthday = '08/07/1987 07:35:00';

export default class About extends Component {
  constructor() {
    super();
    this.state = { checked: false, ageMS: 0 };
    this.enableFullProfile = this.enableFullProfile.bind(this);
    this.renderFull = this.renderFull.bind(this);
    this.calculateAge = this.calculateAge.bind(this);
    this.calculateMilisecondsAge = this.calculateMilisecondsAge.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => this.calculateMilisecondsAge(), 50);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  calculateMilisecondsAge() {
    const ageMS = new Date() - new Date(birthday);

    this.setState({
      ageMS
    });
  }

  calculateAge() {
    const ageMS = this.state.ageMS;
    const age = new Date();
    age.setTime(ageMS);
    const ageYear = age.getFullYear() - 1970;

    return ageYear;
  }

  enableFullProfile(event) {
    event.preventDefault();
    this.setState({ checked: !this.state.checked });
  }

  renderButton() {
    if (this.state.checked) {
      return null;
    }

    return (
      <button className="btn-read-more text-slate-800" onClick={this.enableFullProfile}>
        Read more <FontAwesomeIcon className="ml-1 text-slate-400" icon={faCaretDown} />
      </button>
    );
  }

  renderFull() {
    return (
      <CSSTransition in={this.state.checked} timeout={200} classNames="my-node" unmountOnExit>
        <>
          <p>I studied Electronics and Communications Engineering, got a master's in Project Management, and completed another master's in IT Management  (<a href="http://www.uc3m.es/" rel="noreferrer" title="Charles III University, Madrid - Spain" target="_blank">UC3M</a>).  I also have a postgrad degree in Higher Education. Currently pursuing a Master's in Web Development (<a href="https://www.universidadviu.com/es/" title="Universidad Internacional de Valencia, Valencia - Spain" target="_blank">VIU</a>) with an expected completion in 2024.</p>

          <p className="text-sm">I'm {this.calculateAge()} years old, or if you want to be be precise, <em>{this.state.ageMS} miliseconds</em> old ⏲. I was born and raised in Panama City, Panama, located in Latin America. I speak English (B2) and some Italian (C1).</p>

          <h2 className="text-lg font-semibold mb-3">Experience</h2>

          <p>When it comes to my experience, I have a strong focus on Software Development and Engineering. I'm a full-stack web and mobile developer, but I'm particularly passionate about front-end and UI/UX software development.</p>

          <p> I've been working in the software industry since 2008, primarily for companies based in California. Currently, I'm working as a Software Engineer Contractor.</p>

          <h3 className="text-md mb-3">Other interests</h3>

          <ul className="text-sm list-disc ml-3">
            <li>I believe in OpenSource and Free Software.</li>
            <li>I enjoy traveling 🚂, listening to music 🎶 and <Link to="/photos">capturing moments through photography</Link> 📸.</li>
            <li>I also hold a deep conviction towards Agile, Lean, and Product mindsets.</li>
          </ul>
        </>
      </CSSTransition>
    );
  }

  render() {
    return (
      <div className="text-slate-300">
        <p className="text-lg">My expertise lies in designing, architecting, and developing web/mobile applications, and I also have a background in agile software project management.</p>

        <p className="text-sm">If you'd like to stay connected, you can follow me on Twitter <a href="https://www.twitter.com/demogar" rel="noreferrer">@demogar</a>, and check out my projects on <a href="https://www.github.com/demogar">GitHub</a> and <a href="https://www.gitlab.com/demogar">GitLab</a>. Feel free to connect with me professionally on <a href="http://linkedin.com/in/demogar">LinkedIn</a> as well.</p>

        <h2 className={`text-lg font-semibold mb-3 ${this.state.checked ? "" : "gradient-text-read-more"}`}>Background</h2>

        {this.renderFull()}

        <div className="switcher-container mb-5">
          {this.renderButton()}
        </div>

        <Link to="/es" className="btn-read-more btn-language text-slate-800">
          <FontAwesomeIcon icon={faLanguage} /> Leer en español
        </Link>
      </div>
    );
  }
}
