import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/about"

import "../styles/home.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <div>
        <header>
          <div className="text-xl md:text-2xl font-semibold drop-shadow">
            <p>
              <span className="text-2xl md:text-4xl mb-1 inline-block">
                <span className="say-hi-animation" aria-label="Hi!" role="img">👋</span> Hey there, <strong className="name">I'm Demostenes</strong>
              </span>
              <br />
              an Engineer, web & mobile developer based in Panama City
            </p>
          </div>
        </header>

        <About />
      </div>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="Software Engineer, Web and Mobile Developer in Panama"
    description="Demostenes Garcia (he/him) is a Electronics and Communications Engineer that works as a Software Engineer Consultant for web/mobile. He is based in Panama City, Panama."
  />
)


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
